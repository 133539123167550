import { API, Auth } from "aws-amplify";
import React from "react";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Constant from "../Constant";
import config from "../config.json";

export default function ReceptionConfirm(props) {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();
  return (
    <ReceptionConfirmComp
      {...props}
      navigate={navigate}
      cookies={cookies}
      setCookie={setCookie}
    />
  );
}

class ReceptionConfirmComp extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.setCookie = props.setCookie;
    this.vacant = false;
    this.state = {
      api_name: config.stage,
    };
    this.processing = React.createRef();
  }

  checkVacant = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        car_type: this.cookies.car_type,
        inspection_type: this.cookies.inspection_type,
        reservation_date: this.cookies.reservation_date,
        round: this.cookies.round,
        car_num: this.cookies.car_num,
      },
    };

    await API.get(this.state.api_name, "/reservations/vacant", request)
      .then((r) => {
        console.log(r);
        if (!r) {
          //toast.error("予約の空きがありません。");
          alert("予約に空きがありません。");
          this.props.navigate("/reception_date");
        }
        this.vacant = r;
      })
      .catch((e) => {
        toast.error("予約のチェックエラー");
      });
  };

  createReservation = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const request = {
      headers: {
        Authorization: token,
      },
      body: {
        car_type: this.cookies.car_type,
        inspection_type: this.cookies.inspection_type,
        reservation_date: this.cookies.reservation_date,
        round: this.cookies.round,
        car_num: this.cookies.car_num,
        body_no_1: this.cookies.body_no_1 ? this.cookies.body_no_1 : "",
        plate_no_1: this.cookies.plate_no_1 ? this.cookies.plate_no_1 : "",
        body_no_2: this.cookies.body_no_2 ? this.cookies.body_no_2 : "",
        plate_no_2: this.cookies.plate_no_2 ? this.cookies.plate_no_2 : "",
        body_no_3: this.cookies.body_no_3 ? this.cookies.body_no_3 : "",
        plate_no_3: this.cookies.plate_no_3 ? this.cookies.plate_no_3 : "",
      },
    };

    await API.post(this.state.api_name, "/reservations", request)
      .then((r) => {
        console.log(r);
        this.setCookie("reservation_no_1", r[0].reservation_no);
        this.setCookie("reservation_no_2", r[1] ? r[1].reservation_no : "");
        this.setCookie("reservation_no_3", r[2] ? r[2].reservation_no : "");
        this.props.navigate("/reception_complete");
        //toast.success("予約データを登録しました");
      })
      .catch((e) => {
        toast.error("予約データの登録エラー");
        this.vacant = false;
      });
  };

  nextOnClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.processing.current) {
      return false;
    }
    this.processing.current = true;

    await this.checkVacant();
    console.log(this.vacant);
    if (this.vacant) {
      this.createReservation();
    }
    /*
    this.checkVacant().then((result) => {
      alert(result);
      if (result) {
        this.createReservation();
      }
    });
    */
    /*
    // 予約可チェック
    await this.checkVacant();
    // 予約処理
    this.createReservation();
    */

    setTimeout(() => {
      this.processing.current = false;
    }, 3000);
  };

  render() {
    console.log("render()");
    console.log(this.state);
    console.log(this.cookies);

    return (
      <>
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <Toaster />
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="mb-0">予約受付</h3>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="card card-default">
                <div className="card-body">
                  <div className="row pb-4">
                    <div className="col-12">
                      <label>予約内容を確認してください。</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>予約の種類</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {Constant.CAR_TYPE_LIST[this.cookies.car_type]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>検査の種類</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {
                            Constant.INSPECTION_TYPE_LIST[
                              this.cookies.inspection_type
                            ]
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>希望日</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {this.cookies.reservation_date}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>ラウンド</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {Constant.ROUND_LIST[this.cookies.round]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>台数</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          {Constant.CAR_NUM_LIST[this.cookies.car_num]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>車体番号|ナンバー</label>
                    </div>
                    <div className="col-8">
                      <div className="form-group">
                        <div className="form-text">
                          [1台目]{" "}
                          {this.cookies.body_no_1
                            ? this.cookies.body_no_1
                            : "-"}{" "}
                          |{" "}
                          {this.cookies.plate_no_1
                            ? this.cookies.plate_no_1
                            : "-"}
                        </div>
                        <div className="form-text">
                          [2台目]{" "}
                          {this.cookies.body_no_2
                            ? this.cookies.body_no_2
                            : "-"}{" "}
                          |{" "}
                          {this.cookies.plate_no_2
                            ? this.cookies.plate_no_2
                            : "-"}
                        </div>
                        <div className="form-text">
                          [3台目]{" "}
                          {this.cookies.body_no_3
                            ? this.cookies.body_no_3
                            : "-"}{" "}
                          |{" "}
                          {this.cookies.plate_no_3
                            ? this.cookies.plate_no_3
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-2 pb-2">
                  <div className="row">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          if (
                            this.cookies.site_id != Constant.SITE_ID_1 ||
                            this.cookies.inspection_type ==
                              Constant.INSPECTION_TYPE_4
                          ) {
                            this.props.navigate("/reception_date");
                          } else {
                            this.props.navigate("/reception_car_number");
                          }
                        }}
                      >
                        戻る
                      </button>
                      <>&nbsp;&nbsp;</>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.nextOnClick}
                      >
                        上記内容で予約する
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        {/* /.content-wrapper */}
      </>
    );
  }
}
