import React from "react";
import { useNavigate } from "react-router-dom";

export default function Dashboard(props) {
  const navigate = useNavigate();
  return <DashboardComp {...props} navigate={navigate} />;
}

class DashboardComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content-wrapper">
        {/* Content Wrapper. Contains page content */}
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="mb-0">トップページ</h3>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-info pt-4 pb-4"
                      onClick={() => {
                        this.props.navigate("/reception_car_type");
                      }}
                    >
                      <i className="nav-icon fas fa-calendar-plus"></i> 予約受付
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-info pt-4 pb-4"
                      onClick={() => {
                        this.props.navigate("/confirmation_list");
                      }}
                    >
                      <i className="nav-icon fas fa-calendar-alt"></i> 予約確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-default">
              <div className="card-body">
                <div className="callout callout-info">
                  <h5>陸運事務所【７月分】【８月分】２コース体制のお知らせ</h5>
                  <p>
                    下記の日程について、検査機器の校正等のため検査コースを閉鎖します。
                    <br />
                    ご不便をおかけすることになりますが、皆様のご理解をお願いいたします。
                    <br />
                    <br />
                    【７月】２コース体制
                    <br />
                    ２８日（金）終日（１コース閉鎖）
                    <br />
                    ３１日（月）終日（２コース閉鎖）
                    <br />
                    <br />
                    【８月】２コース体制
                    <br />
                    １日（火）終日（３コース閉鎖）
                  </p>
                </div>

                <div className="callout callout-info">
                  <h5>陸運事務所【１月分】２コース体制のお知らせ</h5>
                  <p>
                    下記の日程について、陸運事務所の検査機器の定期点検等のため検査コースを閉鎖します。
                    <br />
                    ご不便をおかけすることになりますが、皆様のご理解をお願いいたします。
                    <br />
                    <br />
                    【１月分】２コース体制
                    <br />
                    ４日（水）終日
                    <br />
                    ５日（木）終日
                    <br />
                    ２４日（火）終日
                    <br />
                    ２５日（水）終日
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>車検予約システムのウェブ予約ご利用開始のお知らせ</h5>
                  <p>
                    車検予約システムのインターネット予約は11/1(火)からご利用開始となります。よろしくお願い致します。
                  </p>
                </div>
                {/*
                <div className="callout callout-warning">
                  <h5>予約受付の制限中</h5>
                  <p>
                    現在、見込予約・ボイコットの規定を超えた為、予約受付が制限中となります。
                  </p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ1タイトル</h5>
                  <p>お知らせ1内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ2タイトル</h5>
                  <p>お知らせ2内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ3タイトル</h5>
                  <p>お知らせ3内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ4タイトル</h5>
                  <p>お知らせ4内容○○○○○</p>
                </div>
                <div className="callout callout-info">
                  <h5>お知らせ5タイトル</h5>
                  <p>お知らせ5内容○○○○○</p>
                </div>
                */}
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
        {/* /.content-wrapper */}
      </div>
    );
  }
}
